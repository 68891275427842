<template>
  <div class="setting" v-if="!isProcessing">
    <div class="setting__menu" v-for="item in items" :key="item.title">
      <setting-menu :item="item" />
    </div>
  </div>
</template>

<script>
import SettingMenu from '@/components/common/menu.vue'

export default {
  name: 'setting',
  components: { SettingMenu },
  data () {
    return {
      items: [
        { title: 'イメージ画像', content: 'イメージ画像の変更が行えます。', to: 'setting_image' },
        { title: '入会特典', content: '入会特典の修正が行えます。', to: 'setting_privilege' }
      ]
    }
  },
  mounted () {
    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';

.setting {
  width: 100vw;
  max-width: $max_width;
  min-height: calc(100vh - #{$header_height});
  padding: 0 $padding_width $padding_height;
  margin: $header_height auto 0 auto;
}
</style>
